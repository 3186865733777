import Button from '@/components/content/button/Button';
import SupportForm from './SupportForm';

import { useDrawer } from '@/hooks/useDrawer';
import { getValues } from '@/utils';

import { SUPPORT_SUBJECTS, SUPPORT_TYPES } from './types';

import InfoIcon from '@/assets/icons/content/info.svg';

const ContactSupport = () => {
  const { openDrawer } = useDrawer();

  const toggle = () => {
    openDrawer({
      drawerid: 'contact-support',
      children: (
        <SupportForm
          key="contact-support"
          name="support-form"
          subjects={getValues(SUPPORT_SUBJECTS)}
          triggerOtherOn={SUPPORT_SUBJECTS.OTHER}
          prefillData={{ to: SUPPORT_TYPES.SUPPORT }}
          attachment
        />
      ),
      title: 'Contact Support',
    });
  };

  return (
    <Button onClick={toggle} rightSection={<InfoIcon width={18} height={18} />}>Contact Support</Button>
  );
};

export default ContactSupport;
