import { Box } from '@mantine/core';

import TwoFAModal from './TwoFAModal';
import Button from '@/components/content/button/Button';

import { useModal } from '@/hooks/useModal';
import { useTfa } from '../../hooks/useTfa';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/hooks/useToast';

interface TFAProps {
  enabled: boolean;
  createMessage: (msg: string, options: any) => void;
}

const TwoFA = ({
  enabled,
  createMessage,
}: TFAProps) => {
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const { createToast } = useToast();
  const { tokenInfo, setTokenInfo, setUserInfo } = useAuth();

  const onTfaToggleSuccess = (
    tfa: boolean,
    message: string,
  ) => {
    setTokenInfo({ enabled_2fa: tfa });
    setUserInfo({ twofa_activated: tfa });
    createMessage(`2FA Successfully ${message}`, { type: 'success' });
    closeModal();
  };

  const {
    generateTFA,
    activateTFA,
    deactivateTFA,
    TFAData,
    disableButton,
  } = useTfa({
    onActivateSuccess: (tfa) => onTfaToggleSuccess(tfa, 'activated'),
    onDeactivateSuccess: (tfa) => onTfaToggleSuccess(tfa, 'deactivated'),
    onGenerateSuccess: () => {
      openModal();
    },
    onGenerateError: (error?: string) => {
      createToast(error || 'We are sorry, something went wrong.', 'error');
    },
    username: tokenInfo.sub,
  });

  const button = enabled ? { text: 'Deactivate 2FA', cb: openModal } : { text: 'Activate 2FA', cb: generateTFA };
  const tfaAction = enabled ? deactivateTFA : activateTFA;

  return (
    <Box w="100%">
      <Button
        onClick={button.cb}
        disabled={disableButton}
        id="tfa-button"
      >
        {button.text}
      </Button>

      {(TFAData || enabled) && (
        <Modal {...modalProps} title={`${enabled ? 'Deactivate' : 'Activate'} Two-Factor Authentication`} size="500px">
          <TwoFAModal enabled={enabled} data={TFAData} tfaAction={tfaAction} disableAction={disableButton} />
        </Modal>
      )}
    </Box>
  );
};

export default TwoFA;
