import React from 'react';
import { FilterProps } from '../types';
import { SingleSelect as TSingleSelect } from '@/types/meta';
import SingleSelect from '../../combobox/SingleSelect';

const SingleSelectFilter = ({
  filterMeta,
  filter,
  setFilter,
}: FilterProps<TSingleSelect, string>) => {
  const selectedItem = filterMeta.choices.find((choice) => filter === choice.value);
  const value = selectedItem ? { value: `${selectedItem?.value}`, label: selectedItem?.label } : undefined;

  return (
    <SingleSelect
      items={filterMeta.choices.map((choice) => ({ value: `${choice.value}`, label: choice.label }))}
      value={value}
      setValue={(newValue) => {
        setFilter({ [filterMeta.name]: newValue.value });
      }}
    />
  );
};

export default SingleSelectFilter;
