import { postMutation } from '@/lib/react-query/mutate';

import type { PlainObject } from '@/types/general';

const generate = '2fa/generate';
const activate = '2fa/activate';
const deactivate = '2fa/deactivate';

const endpoints: PlainObject = { generate, activate, deactivate };

export const useTFAMutation = <TData, TVariables>(process: string) => (
  postMutation<TData, TVariables>({
    endpoint: endpoints[process],
    requestOptions: {
      error: `There was an error processing the 2FA Request: ${process}`,
    },
    mutationOptions: {
      mutationKey: ['tfa', process],
      meta: {
        avoidAuthRedirection: true,
      },
    },
  })
);
