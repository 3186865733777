import { postMutation } from '@/lib/react-query/mutate';

import { useQueryActions } from '@/hooks/useQueryActions';

import { rfiKeys } from '../../servicerequests/services/keys';
import { SUPPORT_TYPES, type SupportResponse, type SupportType } from '../types';

export const useUnifiedApiPostSupport = (type?: SupportType) => {
  const { invalidateQueries } = useQueryActions();
  const resource = type === SUPPORT_TYPES.INTEL || type === SUPPORT_TYPES.TAKEDOWN ? 'rfis' : 'support';

  return postMutation<SupportResponse>({
    endpoint: `v0/issue/${resource}/`,
    json: false,
    requestOptions: {
      contentType: 'multipart/form-data; boundary=----requestforinformation',
    },
    mutationOptions: {
      onSuccess: () => {
        invalidateQueries([
          rfiKeys.table(),
          rfiKeys.gfx(),
          rfiKeys.dashboard(),
        ]);
      },
    },
  });
};
