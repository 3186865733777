export const uniqueBy = <T extends Record<string, any>>(arr: Array<T>, prop: keyof T): Array<T> => {
  const record: T[] = [];
  const seen: { [key: string]: number } = {};

  for (let i = 0, len = arr.length; i < len; i += 1) {
    const item = arr[i];
    const val = item[prop];

    if (!seen[val]) {
      seen[val] = 1;
      record.push(item);
    }
  }

  return record;
};
