import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Group, Stack } from '@mantine/core';

import Button from '@/components/content/button/Button';

import LogoIcon from '@/assets/logo/logo-complete.svg';

import classes from './Timeout.module.css';

const Timeout = ({
  resetTimeout,
}: { resetTimeout: VoidFunction }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const redirectLogin = () => {
    resetTimeout();
    navigate('/login', { state: { from: location } });
  };

  return (
    <Stack align="center" justify="center" gap={15} classNames={{ root: classes.wrapper }}>
      <Group align="center" justify="center" gap={40} wrap="nowrap">
        <Box component={LogoIcon} w="100%" />
      </Group>

      <div className={classes.text}>
        <p>
          Your session expired due to inactivity. Please login again to keep using Mercury features.
        </p>

        <Button onClick={redirectLogin}>
          Login Again
        </Button>
      </div>
    </Stack>
  );
};

export default Timeout;
