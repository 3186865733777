import { useEffect, useState } from 'react';
import {
  Combobox, ComboboxProps, InputBase, ScrollArea, useCombobox,
} from '@mantine/core';

import ChevronDown from '@/assets/icons/content/chevron-down.svg';

type Value = string;

type Item = { value: Value; label: string; depth?: number };

export interface SingleSelectProps extends ComboboxProps {
  items: Array<Item>;
  label?: string;
  value?: Item;
  setValue: (value: Item) => void;
  forceCustomText?: string | boolean;
  onChange?: (value: Item) => void;
}

const SingleSelect = ({
  items,
  label,
  value,
  setValue,
  forceCustomText,
  onChange,
  ...comboboxProps
}: SingleSelectProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [search, setSearch] = useState(value?.label ?? '');

  const shouldFilterOptions = items.every((item) => item.label !== search);
  const filteredOptions = shouldFilterOptions
    ? items.filter((item) => item.label.toLowerCase().includes(search.toLowerCase().trim()))
    : items;

  const getItem = (val: Value) => items.find((item) => item.value === val) as Item;

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.value} key={item.value} pl={item.depth ? (item.depth * 5) + 20 : undefined}>
      {item.label}
    </Combobox.Option>
  ));

  useEffect(() => {
    if (typeof value === 'undefined') {
      setSearch('');
    }

    if (value?.label !== search) {
      setSearch(value?.label ?? '');
    }
  }, [value]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        const item = getItem(val);

        setValue(item);
        onChange?.(item);
        setSearch(item.label ?? '');
        combobox.closeDropdown();
      }}
      {...comboboxProps}
    >
      <Combobox.Target>
        <InputBase
          rightSectionPointerEvents="none"
          rightSection={<ChevronDown />}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(getItem(value?.value ?? '')?.label ?? '');
          }}
          placeholder="Search value"
          label={label}
          value={search}
          disabled={comboboxProps.disabled}
          onChange={(event) => {
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize type="scroll" mah={200}>
            {forceCustomText && <Combobox.Empty>{forceCustomText}</Combobox.Empty>}
            {!options.length && !forceCustomText && <Combobox.Empty>Nothing found</Combobox.Empty>}
            {options.length > 0 && !forceCustomText && options}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default SingleSelect;
