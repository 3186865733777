import { type PropsWithChildren, createContext, useCallback, useMemo } from 'react';
import { useQuery, type QueryKey, type UseQueryResult, keepPreviousData } from '@tanstack/react-query';

// Hooks
import { useTable } from '@/hooks/useTable';
import { useUserId } from '@/hooks/useUserId';
import { fetchData } from '@/lib/fetch';
import { stringifyParams } from '@/utils/stringifyParams';

// Types
import type { UseTableParams, UseTableReturn } from '@/hooks/useTable';
import type { FetchError, UserId } from '@/types/api';
import type { TableData } from './services/tableTransforms';
import type { ServerPaginatedTable } from '@/types/mercury-data-types';
import { getValues } from '@/utils';

type URLWithParams = (param: string) => string;
type TableEndpoint = (userId: UserId) => URLWithParams;

export interface MercuryTableContextValue<TData> {
  queryKey: QueryKey;
  queryKeyDef: QueryKey;
  tableUrl: URLWithParams;
  query: UseQueryResult<TableData<Array<TData>>, FetchError>;
  table: UseTableReturn<TData>;
  useResponseError?: boolean;
  queryReactTo: Record<string, any>;
}

interface MercuryTableProviderProps<TResponse = unknown, TData = TResponse> extends UseTableParams<TResponse> {
  tableEndpoint: TableEndpoint;
  queryKey: QueryKey;
  dataTransform: (data: TData & Array<TData> & ServerPaginatedTable<Array<TData>>) => TableData<Array<TResponse>>;
  queryReactTo?: Record<string, any>;
  dependsOn?: boolean;
  noContent?: string;
  useResponseError?: boolean;
  keepDataAlive?: boolean;
}

export const MercuryTableContext = createContext<MercuryTableContextValue<any> | null>(null);

export const MercuryTableProvider = <TResponse extends unknown, TData = TResponse>({
  tableEndpoint,
  queryKey,
  columns,
  dataTransform,
  basic,
  children,
  queryReactTo = {},
  initialState = {},
  columnVisibility,
  namespace,
  dependsOn,
  noContent,
  useResponseError,
  keepDataAlive,
}: PropsWithChildren<MercuryTableProviderProps<TResponse, TData>>) => {
  const userId = useUserId();

  const tableUrl = tableEndpoint(userId);

  const table = useTable<TResponse>({
    columns,
    basic,
    initialState,
    columnVisibility,
    namespace,
  });

  const state = { ...table.state, ...queryReactTo };
  const queryKeyDef = !basic ? [...queryKey, state] : queryKey;
  const params = stringifyParams(state);
  const hasParams = getValues(queryReactTo?.filter ?? {}).length > 0
    || (queryReactTo.search && queryReactTo.search !== '');
  const noContentMessage = noContent || `No ${namespace ?? ''} data available`;

  const query = useQuery({
    queryKey: queryKeyDef,
    queryFn: ({ signal }) => fetchData(
      {
        noContent: noContentMessage,
        error: `Sorry! There was an error while trying to get the ${namespace ?? ''} data`,
        context: { source: 'table' },
        endpoint: tableUrl(`?${params}`),
      },
      signal,
    ),
    placeholderData: keepPreviousData,
    enabled: !dependsOn,
    gcTime: hasParams && !keepDataAlive ? 0 : 60 * 5000,
    select: useCallback(
      (data: TData & Array<TData> & ServerPaginatedTable<Array<TData>>) => dataTransform(data),
      [],
    ),
  });

  const value = useMemo(
    () => ({
      tableUrl,
      queryKey,
      queryKeyDef,
      query,
      table,
      useResponseError,
      queryReactTo,
    }),
    [query.data, query.isLoading, query.isFetching, table.state, table.instanceConfiguration.state?.rowSelection],
  );

  return (
    <MercuryTableContext.Provider value={value}>
      {children}
    </MercuryTableContext.Provider>
  );
};
