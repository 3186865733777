import { Route } from 'react-router-dom';

import type { RoutePathDefinition } from '@/types/routes';

interface RouteRendererProps {
  routes: Array<RoutePathDefinition>;
}

const mapRoutes = ({
  routes,
}: RouteRendererProps) => routes.map((route) => {
  if (route.index) {
    return <Route key={route.path} index element={route.element} />;
  }

  if (route.layout && route.children) {
    return (
      <Route key={route.path} element={route.element}>
        {mapRoutes({ routes: route.children })}
      </Route>
    );
  }

  if (route.children) {
    return (
      <Route key={route.path} path={route.path} element={route.element}>
        {mapRoutes({ routes: route.children })}
      </Route>
    );
  }

  return (
    <Route key={route.path} path={route.path} element={route.element} />
  );
});

export default mapRoutes;
