import { useEffect, useState } from 'react';

import Button from '@/components/content/button/Button';
import News from '@/features/news/News';
import TrialsModal from './TrialsModal';

import { useModal } from '@/hooks/useModal';
import { useGetNews } from '@/features/news/services/news';
import { hasUnreadItems, setReadItems } from '@/features/news/utils';
import { hasTrials, setTrials } from '@/utils/hasTrials';
import { useAuth } from '@/hooks/useAuth';

import type { SubscriptionsTrials } from '@/types/mercury-data-types';

const initialModals = (
  trials: Array<SubscriptionsTrials>,
) => {
  const modals = [];

  if (hasTrials(trials)) {
    modals.push({
      content: <TrialsModal />,
      onClose: () => setTrials(trials),
    });
  }

  return modals;
};

const InitialModals = () => {
  const { userInfo, userInfoQuery } = useAuth();
  const [modals, setModals] = useState<ReturnType<typeof initialModals>>([]);
  const news = useGetNews();

  const {
    Modal: MercuryModal,
    openModal,
    modalProps,
    closeModal,
  } = useModal();
  useEffect(() => {
    if (modals.length > 0) {
      openModal();
    }
  }, [modals.length]);

  useEffect(() => {
    if (news.isSuccess && hasUnreadItems(news.data[0])) {
      setModals((modals) => [
        ...modals,
        {
          content: <News newsItem={news.data[0]} />,
          onClose: () => setReadItems(news.data[0]),
        },
      ]);
    }
  }, [news.isSuccess]);

  useEffect(() => {
    if (userInfoQuery.isSuccess) {
      setModals((modals) => [
        ...modals,
        ...initialModals(userInfo.entitlements_trial),
      ]);
    }
  }, [userInfoQuery.isSuccess]);

  const dequeue = () => {
    const newQueue = [...modals];
    newQueue.shift();
    setModals(newQueue);
  };

  const currentModal = modals[0];

  const onClose = () => {
    currentModal.onClose();

    if (modals.length > 1) {
      dequeue();
      return;
    }

    closeModal();
  };

  if (!currentModal) return null;

  return (
    <>
      <MercuryModal
        {...modalProps}
        size="500px"
        onClose={onClose}
      >
        {modals[0].content}

        <Button onClick={onClose} mt={15} fullWidth>Close</Button>
      </MercuryModal>
    </>
  );
};

export default InitialModals;
