import { useMemo } from 'react';

import TableInstance from '@/components/content/table/TableInstance';
import TablePlaceholder from '@/components/content/table/TablePlaceholder';

import { useMercuryTable } from './hooks/useMercuryTable';

import type { CommonTableProps, TableSelectionProps } from '@/types/table';

interface TableWrapperProps<T> extends Partial<CommonTableProps<T>>, TableSelectionProps<T> { }

const TableWrapper = <T extends unknown>({ ...tableProps }: TableWrapperProps<T>) => {
  const {
    table,
    query,
    queryKey,
    useResponseError,
  } = useMercuryTable<T>();

  const defaultData = useMemo(() => [], []);

  const { tableData, pageCount } = query.data ?? {};

  return (
    <TablePlaceholder isPending={query.isPending}>
      <TableInstance
        data={tableData ?? defaultData}
        pageCount={pageCount}
        instanceConfiguration={{
          ...table.instanceConfiguration,
          getRowId: (row: any) => (row.issue_id ? row.issue_id : row.id),
        }}
        id={queryKey.join('-')}
        isLoading={query.isFetching}
        isError={query.isError && query.error?.code !== 204}
        errorMessage={useResponseError ? query.error?.responseError : query.error?.message}
        noContentMessage={query.error?.message}
        {...tableProps}
      />
    </TablePlaceholder>
  );
};

export default TableWrapper;
