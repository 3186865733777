import { type ReactNode, useCallback } from 'react';
import { Tooltip } from '@mantine/core';
import { useMemoizedFn } from 'ahooks';

import DateRange from '@/components/content/filters/components/DateRange';
import Single from '@/components/content/filters/components/Single';
import MultiSelect from '@/components/content/filters/components/MultiSelect';
import ConditionalWrapper from '@/components/content/conditionalwrapper/ConditionalWrapper';

import { Cardinalities, Single as TSingle, Multiple, TDate } from '@/types/meta';
import type { MetaStore } from '@/hooks/useMetaStore';

import classes from './Meta.module.css';
import SingleSelectFilter from '@/components/content/filters/components/SingleSelect';

interface ComponentProps {
  meta: TDate | Multiple | TSingle;
  store: MetaStore;
}

const FiltersComps: Record<
Cardinalities.range | Cardinalities.multiple | Cardinalities.single | Cardinalities.listsingle,
  typeof DateRange | typeof MultiSelect | typeof Single | typeof SingleSelectFilter
> = {
  [Cardinalities.range]: DateRange,
  [Cardinalities.multiple]: MultiSelect,
  [Cardinalities.single]: Single,
  [Cardinalities.listsingle]: SingleSelectFilter,
};

const MetaComponent = ({ meta, store }: ComponentProps) => {
  const hasDefaultChoice = !!meta.default_choice;
  const Component = FiltersComps[meta.cardinality];

  const onClearFilter = useMemoizedFn(() => {
    const mStore = meta.non_meta ? store.customMeta : store.filters;

    if (hasDefaultChoice) {
      mStore.reset(meta.name);
      return;
    }

    mStore.clear(meta.name);
  });

  const onDispatch = useMemoizedFn((filter: Record<string, any>) => {
    const mStore = meta.non_meta ? store.customMeta : store.filters;
    mStore.set(filter);
  });

  const wrapperTooltip = useCallback((children: ReactNode) => (
    <Tooltip label={`This filter will reset back to the default value: ${meta.default_choice}`}>
      {children}
    </Tooltip>
  ), []);

  if (meta.cardinality === Cardinalities.multiple && !meta.choices) {
    return null;
  }

  return (
    <div>
      <div className={classes.titleWrapper}>
        <h3 className={classes.metaTitle}>
          {meta.label}
        </h3>
        {meta.cardinality !== 'single' && (
          <ConditionalWrapper condition={hasDefaultChoice} wrapper={wrapperTooltip}>
            <button type="button" onClick={onClearFilter} className={classes.clear}>
              {meta.default_choice ? 'Reset' : 'Clear'}
            </button>
          </ConditionalWrapper>
        )}
      </div>

      <div className={classes.metaComponentWrapper}>
        <Component
          filterMeta={meta as any}
          filter={store.filters.state[meta.name] || store.customMeta.state[meta.name] || null}
          clearFilter={onClearFilter}
          setFilter={onDispatch}
        />
      </div>
    </div>
  );
};

export default MetaComponent;
