import MercuryButton from '@/components/content/button/Button';

import { useLogout } from '../services/logout';
import { useToast } from '@/hooks/useToast';
import { useSession } from '@/context/SessionContext';

const Logout = () => {
  const { createToast } = useToast();
  const {
    serverLogout,
    clientLogout,
  } = useLogout();
  const { stopSession } = useSession();

  const { mutate: logout, isPending: isLoginOut } = serverLogout();

  const onClick = () => {
    logout('', {
      onSuccess: () => {
        stopSession();
        clientLogout();
      },
      onError: () => {
        createToast('We had an issue logging you out. Please try again.', 'error');
      },
    });
  };

  return (
    <MercuryButton
      id="logout"
      justify="flex-start"
      onClick={onClick}
      disabled={isLoginOut}
      variant="subtle"
      size="md"
      loading={isLoginOut}
    >
      Logout
    </MercuryButton>
  );
};

export default Logout;
