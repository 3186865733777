import { Box, Flex, Stack } from '@mantine/core';

// Components
import TwoFA from './TwoFA';
import PasswordChange from './PasswordChange';
import Message from '@/components/content/message/Message';
import PillsList from '@/components/content/pills/PillsList';
import Pill from '@/components/content/pills/Pill';

// Hooks
import { useMessage } from '@/hooks/useMessage';
import { useAuth } from '@/hooks/useAuth';
import { MercuryCol, MercuryGrid } from '@/components/grid/Grid';
import { useMSSP } from '@/hooks/useIsMSSP';

const ProfileInfo = () => {
  const { createMessage, removeMessage, message } = useMessage();
  const { userInfo, tokenInfo } = useAuth();
  const { isMSSP } = useMSSP();

  const lists = [
    { title: 'Industry Sectors', values: userInfo.industry_sectors },
    { title: 'Technologies', values: userInfo.technologies },
    { title: 'Locations', values: userInfo.locations },
  ];

  const tfaEnabled = (userInfo.twofa_activated && tokenInfo.enabled_2fa)
    || (userInfo.twofa_activated && !tokenInfo.enabled_2fa);
  return (
    <MercuryGrid>
      <MercuryCol>
        <Stack gap={25}>
          <Message message={message} removeMessage={removeMessage} closeButton />

          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Username</Flex>
            <Box w="100%">
              <Pill value={tokenInfo.sub} component="span" size="big" />
            </Box>
          </Flex>

          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Email</Flex>
            <Box w="100%">
              <Pill value={tokenInfo.sub} component="span" size="big" />
            </Box>
          </Flex>

          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Company</Flex>
            <Box w="100%">
              <Pill value={tokenInfo.client_name} component="span" size="big" />
            </Box>
          </Flex>

          {/* Means Client has the MSSP role and cannot configure 2FA */}
          {!isMSSP && (
            <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
              <Flex component="span" flex="40%">2FA</Flex>
              <TwoFA
                enabled={tfaEnabled}
                createMessage={createMessage}
              />
            </Flex>
          )}

          <Flex direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
            <Flex component="span" flex="40%">Password</Flex>
            <PasswordChange createMessage={createMessage} />
          </Flex>

          {lists.map(({ title, values }) => (
            <Flex key={title} direction={{ base: 'column', m: 'row' }} wrap="nowrap" gap={{ base: 5, m: 20 }}>
              <Flex component="span" flex="40%">{title}</Flex>
              <Box w="100%">
                <PillsList pills={Object.values(values)} size="big" />
              </Box>
            </Flex>
          ))}
        </Stack>
      </MercuryCol>
    </MercuryGrid>
  );
};

export default ProfileInfo;
