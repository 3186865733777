import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useAdminRedirect } from '../hooks/useAdminRedirection';
import { useQueryActions } from '../../../hooks/useQueryActions';

import { getMutation } from '@/lib/react-query/mutate';

export const useLogout = () => {
  const navigate = useNavigate();
  const { clear } = useQueryActions();
  const { returnToMercuryOrigin, isInAdminDomain } = useAdminRedirect();

  function serverLogout() {
    return getMutation({
      endpoint: 'logout',
      mutationOptions: {
        onSuccess: () => {
          clear();
          Sentry.setUser(null);
        },
      },
    });
  }

  function clientLogout() {
    if (isInAdminDomain()) {
      returnToMercuryOrigin('login');
      return;
    }

    navigate('/login', { replace: true, state: { avoidPrecheck: true } });
  }

  return {
    serverLogout,
    clientLogout,
  };
};
